import { Theme } from "./theme"

export const textColor = (name: keyof Theme["color"]["text"]) => ({
  theme,
}: {
  theme: Theme
}) => theme.color.text[name]

export const backgroundColor = (name: keyof Theme["color"]["background"]) => ({
  theme,
}: {
  theme: Theme
}) => theme.color.background[name]

export const shadowColor = (name: keyof Theme["color"]["shadow"]) => ({
  theme,
}: {
  theme: Theme
}) => theme.color.shadow[name]

export const mainGradient = (name: keyof Theme["color"]["mainGradient"]) => ({
  theme,
}: {
  theme: Theme
}) => theme.color.mainGradient[name]

export const supportColor = (name: keyof Theme["color"]["support"]) => ({
  theme,
}: {
  theme: Theme
}) => theme.color.support[name]

export const minBreakpoint = (name: keyof Theme["breakpoint"]) => ({
  theme,
}: {
  theme: Theme
}) => theme.breakpoint[name] + "rem"

export const maxBreakpoint = (name: keyof Theme["breakpoint"]) => ({
  theme,
}: {
  theme: Theme
}) => theme.breakpoint[name] - 0.01 + "rem"
